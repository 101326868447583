.btn-light-primary {
    background-color: rgba(115, 102, 255, 0.1);
    color: #7366FF;

    &.solid {
        background-color: rgb(241 239 255);
    }

    &:hover {
        background-color: var(--primary);
    }
}

.btn-light-secondary {
    background-color: rgba(255, 51, 100, 0.08);
    color: #FF3364;

    &.solid {
        background-color: rgb(255 239 243);
    }

    &:hover {
        background-color: var(--pink);
    }
}

.btn-light-success {
    background-color: rgba(84, 186, 74, 0.15);
    color: #54BA4A;

    &.solid {
        background-color: rgb(241 239 255);
    }

    &:hover {
        background-color: var(--success);
    }
}

.btn-light-danger {
    background-color: #fff7f7;
    color: #FC4438;

    &.solid {
        background-color: rgb(255 239 243);
    }

    &:hover {
        background-color: var(--danger);
    }
}

.btn-light-warning {
    background-color: #ffecc7;
    color: #FFAA05;

    &.solid {
        background-color: rgb(241 239 255);
    }

    &:hover {
        background-color: var(--warning);
    }
}

.btn-light-info {
    background-color: #d3f4fe;
    color: #16C7F9;

    &.solid {
        background-color: rgb(241 239 255);
    }

    &:hover {
        background-color: var(--info);
    }
}

hr {
    &.hr-text {
        position: relative;
        border: none;
        height: 1px;
        background: #ced4da59;

        &::before {
            content: attr(data-content);
            display: inline-block;
            background: #fff;
            font-size: 0.85rem;
            color: #999;
            border-radius: 30rem;
            padding: 0.2rem 2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.mw-md {
    min-width: 175px;
}

@media screen and (min-width: 992px) {
    .mw-md {
        min-width: 250px;
    }
}